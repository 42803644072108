<template>
    <v-dialog v-model="showModalCancelamento" width="500" persistent>
        <v-card>
            <v-icon @click="changeShow()" class="fechar"
                >mdi-close mdi-color-black</v-icon
            >
            <v-card-title>{{ $t_("cancelamento") }}</v-card-title>
            <v-card-text class="mt-5"
                >{{ $t_("cancelamento_mensagem") }} <br />
                {{
                    produto.tpproduto
                        ? produto.tpproduto
                        : produto.produto +
                          " - " +
                          $util.formatDateHour(produto.dt_apre)
                }}</v-card-text
            >
            <v-divider></v-divider>
            <v-card-actions
                class="d-flex justify-center align-center flex-row pa-3"
            >
                <v-btn
                    :color="$config.dados.corsecundaria"
                    text
                    @click="cancelarCompra()"
                    v-once
                >
                    {{ $t_("confirmarCancelamento") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalConfirmarCancelamento",
    props: {
        showModalCancelamento: Boolean,
        idmovimentacao: String,
        idmovimentacaoitem: String,
        produto: Object,
    },
    methods: {
        cancelarCompra() {
            let dados = {
                class: "SuperIngresso",
                method: "cancelaCompra",
                params: [this.idmovimentacao, [this.idmovimentacaoitem]],
            };
            this.$axios.post("router.php", dados).then((response) => {
                if (response && response.aguarde) {
                    this.$google.reembolsoParcial(
                        [this.produto],
                        this.idmovimentacao
                    );
                    this.$axios.modal.show = true;
                    this.$axios.modal.titulo = this.$t_("cancelamentoSucesso");
                    this.$axios.modal.conteudo = this.$t_(
                        "aguardeConfirmacaoCancelamento"
                    );
                    this.changeShow();
                }
            });
        },
        changeShow() {
            let changeDialog = this.showModalCancelamento;
            changeDialog = !changeDialog;
            this.$emit("update-dialog-cancel", changeDialog);
        },
    },
};
</script>

<style scoped>
.v-card__title {
    justify-content: center;
}
</style>
